import * as React from 'react';
import classnames from 'classnames';
import { ISectionProps } from '../Section.types';
import ResponsiveContainer from '../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import { TestIds } from '../constants';
import BackgroundX from '../../../BackgroundX/viewer/BackgroundX';
import style from './styles/Section.scss';

export type SectionProps = ISectionProps & {
  className?: string;
  supportPinnedToContainer?: boolean; // TODO: remove after merging https://github.com/wix-private/thunderbolt/pull/18838
};

const Section: React.FC<SectionProps> = ({
  id,
  skin = 'RectangleArea',
  className = style[skin],
  containerRootClassName = '',
  containerProps,
  children,
  tagName,
  background,
  getPlaceholder,
  supportPinnedToContainer,
  onClick,
  onDblClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const TagName = tagName || ('section' as keyof JSX.IntrinsicElements);
  const { shouldOmitWrapperLayers } = containerProps;
  const classNames = classnames(className, containerRootClassName, {
    [style.shouldOmitWrapperLayers]: shouldOmitWrapperLayers,
  });
  return (
    <TagName
      id={id}
      className={classNames}
      data-testid={TestIds.section}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {background && (
        <BackgroundX {...background} getPlaceholder={getPlaceholder} />
      )}
      {supportPinnedToContainer ? (
        children()
      ) : (
        <ResponsiveContainer {...containerProps}>
          {children}
        </ResponsiveContainer>
      )}
    </TagName>
  );
};

export default Section;
