import SectionComponent from '@wix/thunderbolt-elements/src/components/Sections/Section/viewer/Section';


const Section = {
  component: SectionComponent
};


export const components = {
  ['Section']: Section
};


// temporary export
export const version = "1.0.0"
